<template>
  <f7-page class="register-page">
    <meta name="robots" content="noindex" />

    <section class="register-container">
      <div class="container">
        <img src="@/assets/images/svg/login.svg" :alt="$t.getTranslation('LBL_UPDATE_PASSWORD')" loading="lazy" />

        <div class="info">
          <h2>{{ $t.getTranslation("LBL_STEP_3_OF_3") }}</h2>
          <h1>{{ $t.getTranslation("LBL_UPDATE_PASSWORD") }}</h1>

          <p>{{ $t.getTranslation("LBL_UPDATE_PASSWORD_SUB") }}</p>
        </div>
        <f7-list no-hairlines>
          <f7-list-input
            v-model:value="formData.Password"
            name="Password"
            :required="validationRules?.Password?.required"
            :minlength="validationRules?.Password?.minimumLength"
            :maxlength="validationRules?.Password?.maximumLength"
            :label="$t.getTranslation('LBL_PASSWORD')"
            :info="$t.getTranslation('LBL_PASSWORD_INFO')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            :type="isPasswordShown ? 'text' : 'password'"
            placeholder="********"
            validate
          >
            <template #content-end>
              <f7-link tabindex="-1" class="show-password" @click="togglePassword">
                <font-awesome-icon :icon="['fas', 'eye']" fixed-width />
              </f7-link>
            </template>
          </f7-list-input>
          <f7-list-input
            v-model:value="formData.ConfirmPassword"
            name="ConfirmPassword"
            :required="validationRules?.ConfirmPassword?.required"
            :minlength="validationRules?.ConfirmPassword?.minimumLength"
            :maxlength="validationRules?.ConfirmPassword?.maximumLength"
            :label="$t.getTranslation('LBL_CONFIRM_PASSWORD')"
            :info="$t.getTranslation('LBL_CONFIRM_PASSWORD_INFO')"
            :error-message="$t.getLanguage() != 'EN' ? $t.getTranslation('LBL_ERROR_FIELD') : ''"
            floating-label
            outline
            :type="isConfirmPasswordShown ? 'text' : 'password'"
            placeholder="********"
            validate
          >
            <template #content-end>
              <f7-link tabindex="-1" class="show-password" @click="toggleConfirmPassword">
                <font-awesome-icon :icon="['fas', 'eye']" fixed-width />
              </f7-link>
            </template>
          </f7-list-input>
        </f7-list>

        <f7-button fill large raised preloader :loading="isButtonProcessing" :disabled="isButtonProcessing || isDisableNextButton" @click="next">
          {{ $t.getTranslation("LBL_SUBMIT") }}
        </f7-button>

        <f7-button large @click="cancelForgotPassword"> {{ $t.getTranslation("LBL_CANCEL") }}</f7-button>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted, inject } from "vue";
import { $HTTP } from "@/utils/axios";
import { useStore } from "@/store";
import { helpers } from "@/utils/helpers.js";
import { validations } from "@/utils/validations";
import validationRulesConfig from "@/validationRules";

export default defineComponent({
  name: "ForgotPasswordPrimaryPage",
  components: {},
  props: { f7router: Object },
  setup(props) {
    const $t = inject("$translation");

    const store = useStore();

    const isPasswordShown = ref(false);
    const isConfirmPasswordShown = ref(false);
    const isButtonProcessing = ref(false);

    const formData = reactive({
      Password: "",
      ConfirmPassword: "",
    });

    let validationRules = {};
    for (let keys in formData) {
      if (validationRulesConfig && validationRulesConfig[keys]) {
        validationRules[keys] = validationRulesConfig[keys];
      }
    }

    const togglePassword = () => {
      if (isPasswordShown.value) {
        hidePassword();
      } else {
        showPassword();
      }
    };

    const toggleConfirmPassword = () => {
      if (isConfirmPasswordShown.value) {
        hideConfirmPassword();
      } else {
        showConfirmPassword();
      }
    };

    const showPassword = (event) => {
      isPasswordShown.value = true;
    };

    const hidePassword = (event) => {
      isPasswordShown.value = false;
    };

    const showConfirmPassword = (event) => {
      isConfirmPasswordShown.value = true;
    };

    const hideConfirmPassword = (event) => {
      isConfirmPasswordShown.value = false;
    };

    const validate = (showNotifications) => {
      let validationRules = {};
      for (let keys in formData) {
        if (validationRulesConfig && validationRulesConfig[keys]) {
          validationRules[keys] = validationRulesConfig[keys];
        }
      }

      const isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const isDisableNextButton = computed(() => {
      let isValid = validate(false);

      return !isValid;
    });

    const next = async () => {
      try {
        let isValid = validate(true);

        const isPasswordInvalid = formData.Password !== formData.ConfirmPassword;
        if (isPasswordInvalid) {
          throw new Error($t.getTranslation("LBL_ERROR_PASSWORD_CONFIRM_PASSWORD_MATCH"));
        }

        if (isValid) {
          isButtonProcessing.value = true;
          helpers.showLoader();

          store.dispatch("forgotpassword/setData", {
            ...formData,
          });

          const forgotPasswordInfo = store.getters["forgotpassword/getData"];

          let res = await $HTTP.post("/mobile/forgotpassword", forgotPasswordInfo);

          if (res && res.status === 200 && res.data && res.data.data) {
            isButtonProcessing.value = false;
            helpers.hideLoader();

            let returnData = res.data.data;
            if (returnData && returnData.isPasswordUpdated) {
              store.dispatch("forgotpassword/setData", {
                IsPasswordUpdated: 1,
              });

              props.f7router.navigate("/forgotpassword/success/");
              return;
            }
          }

          throw new Error($t.getTranslation("ERR_SERVER_NOT_RESPONDING"));
        }
      } catch (err) {
        isButtonProcessing.value = false;
        helpers.hideLoader();

        helpers.catchError(err, true);
      }
    };

    const cancelForgotPassword = () => {
      store.dispatch("forgotpassword/resetData");
      props.f7router.navigate("/login/");
    };

    return {
      formData,
      validationRules,
      isPasswordShown,
      showPassword,
      hidePassword,
      isConfirmPasswordShown,
      showConfirmPassword,
      hideConfirmPassword,
      isButtonProcessing,
      isDisableNextButton,
      next,
      cancelForgotPassword,
      togglePassword,
      toggleConfirmPassword,
    };
  },
});
</script>

<style>
.register-container {
  margin-top: 80px;
}
</style>
